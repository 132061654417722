const ERROR = {
  SYSTEM_ERROR: "System error. Please try again later!",
};

const PATH = {
  NOPAGE: "*",
  SIGN_IN: "/",
  SIGN_UP: "/sign-up",
  FORGET_PASSWORD: "/forget-password",
  RESET_PASSWORD: "/reset-password",
  DASHBOARD: "/dashboard",
  LANDING_PAGE: "/landing-page",
  APPOINTMENTS: "/appointments",
  BOOKAPPOINTMENTS: "/book-an-appointments",
  CONSULTVIEW: "/consult-view",
  PRESCRIPTION: "/prescription",
  REPORTS: "/reports",
  BILLING: "/billing",
  UPLOAD: "/upload",
  EXECUTIVEHEALTHCHECKUPS: "/executive-health-checkups",
  EXECUTIVEViewDetail: "/executive-health-checkups/details",
  BOOKNOWFORM: "/book-now-form",
  LAB_TEST: "/lab-test",
  LAB_TEST_DETAIL_VIEW: "/lab-test-detail-view",
  DOCUMENT_VIEW: "/document-view",
};

const APP_SETTINGS = {
  API_PATH: {
    ACCOUNT: {
      getToken: "Account/GetToken",
    },
  },
};

const AZURE_BLOB_BASE_URL = "blob_url_here";

const ROLE = {
  ADMIN1: 1,
  ADMIN2: 2,
  ADMIN3: 3,
};

const TOASTER_STYLING_VALUES = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const PAGINATION_PAGE_SIZE = 10;

// PRODUCTION URL'S to client-auto-deployment
const REPORTING_URL = "https://rpt.buchhospital.com/api/report";
const DOWNLOAD_REPORTING_URL = "https://rpt.buchhospital.com/api/downloadreport";


// DEV URL'S deploy to auto-deployment
// const REPORTING_URL = "https://buch-reporting.azurewebsites.net/api/report";
// const DOWNLOAD_REPORTING_URL = "https://buch-reporting.azurewebsites.net/api/downloadreport";



export {
  ERROR,
  PATH,
  APP_SETTINGS,
  ROLE,
  TOASTER_STYLING_VALUES,
  AZURE_BLOB_BASE_URL,
  PAGINATION_PAGE_SIZE,
  REPORTING_URL,
  DOWNLOAD_REPORTING_URL,
};
